import { memo } from "react";


export function SvgIcon({ name, className = "", onClick, color }) {
    const href = `#${name}`;

    return (
        <svg fill={color} onClick={onClick} className={className}>
            <use
                ref={(el) => {
                    el?.setAttribute("href", href);
                    el?.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", href);
                }}
            />
        </svg>
    );
}

export default memo(SvgIcon);
