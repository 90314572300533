import { Header } from "../../components/header/header";
import "./home.scss";
import { ImageLink } from "../../components/image/image";
import { Button } from "../../components/button/button";
import { WhyBlock } from "./why-block/why-block";
import { HomeCarousel } from "./home-carousel/home-carousel";
import { MobileApp } from "./mobile-app/mobile-app";
import { ModulesStats } from "../../components/modules-stats/modules-stats";
import { Footer } from "../../components/footer/footer";
import { useState } from "react";
import { Loader } from "../../components/loader/loader";
import { HomeText } from "./home-text/home-text";

export const Home = () => {
    const [loading, setLoading] = useState(true);

    return (
        <>
            <div className='home-wrapper'>
                <div className='home'>
                    <div className='container'>
                        <Header />
                        <div className='home-container'>
                            <div className='home-content'>
                                <h1 className='home-content__title'>The Hobby Smart Case</h1>
                                <p className='home-content__text'>The Hobby Smart Case is an innovative, tech-enhanced
                                    storage
                                    solution
                                    for graded card collectors, offering real-time tracking, environmental monitoring,
                                    and
                                    smart
                                    organization, all managed through an intuitive mobile app, setting a new standard in
                                    collectible
                                    card protection and management.</p>

                                <ModulesStats />

                                <div className='home-content__buttons'>
                                    <Button color='transparent'>Learn more</Button>
                                    <a href="https://presale.hobbysmartcase.com/" target="_blank" rel="noreferrer">
                                        <Button color='white' className='home-content__button-white'>
                                            Reserve now
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.60498 2.85095L12.9992 7.99996L7.60498 13.149" stroke="#0A0908"
                                                      strokeWidth="1.60714" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.9994 7.99994L2.49951 7.99994" stroke="#0A0908"
                                                      strokeWidth="1.60714"
                                                      strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </Button>
                                    </a>
                                </div>
                            </div>
                            <div className='home-content__image-wrapper'>
                                <ImageLink name='smart-case.gif' onLoad={() => setLoading(false)} />
                            </div>
                        </div>
                    </div>
                    {loading && <Loader />}
                </div>
            </div>
            <WhyBlock />
            <HomeCarousel />
            <MobileApp />
            <HomeText />
            <Footer />
        </>
    )
}
