import "./home-text.scss";
import { ImageLink } from "../../../components/image/image";

export const HomeText = () => {
    return (
        <div className="home-text">
            <div className="container">
                <div className="home-text__content">
                    <div className="home-text__image-wrapper">
                    <ImageLink name='cards.webp' className='iphone' />
                </div>
                    <div className="home-text__info">
                    <div className="home-text__info-title">
                        Hobby Smart Case revolutionizes graded card collecting management and protection
                    </div>
                    <div className="home-text__info-text">
                        The Hobby Smart Case is born from a passion for collecting and a desire for a smarter way to
                        protect and manage valuable card collections. It’s not just a case; it’s a comprehensive system
                        designed with advanced features like , real-time tracking, environmental monitoring, and mobile
                        app integration, all tailored for the modern collector
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
