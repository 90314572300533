import "./why-block.scss";
import { ImageLink } from "../../../components/image/image";
import { Button } from "../../../components/button/button";

export const WhyBlock = () => {
    return (
        <div className='why-block'>
            <div className='container container-card'>
                <div className='why-block__card card'>
                    <div className='card__info'>
                        <div className='card__info-title'>Get An Exclusive Discount</div>
                        <div className='card__info-text'>We're Launching Soon. Get an exclusive discount by signing up now!</div>
                    </div>
                    <div className='card__info-form'>
                        <a href="https://presale.hobbysmartcase.com/" target="_blank" rel="noreferrer">
                            <Button className='invite-button'>Reserve Launch Invite</Button>
                        </a>
                    </div>
                </div>
            </div>
            <div className='container container-blocks' id='whyHobbySmartCase'>
                <div className='why-block__wrapper'>
                    <div className='why-block__content'>
                        <h2 className='why-block__content-title'>
                            Why Hobby <br /> Smart Case?
                        </h2>
                        <div className='why-block__list'>
                            <div className='why-block__list-top'>
                                <div className='why-block__list-top-texts'>
                                    <div className='why-block__text-item text-item2'>
                                        <div className='why-block__text-item-content'>
                                            <span>Real-Time Tracking: </span> Instantly locate any graded card
                                            within the case with LED indicators, reducing your search time and enhancing
                                            collection
                                            management.
                                        </div>
                                    </div>
                                    <div className='why-block__text-item text-item4'>
                                        <div className='why-block__text-item-content'>
                                            <span>Inventory Management: </span>
                                            Useful for collectors managing large inventories, facilitating
                                            quick updates and valuations with the mobile app.
                                        </div>
                                    </div>
                                    <div className='why-block__text-item text-item6'>
                                        <div className='why-block__text-item-content'>
                                            <span>Retail and Display: </span>
                                            A professional way for shops and sellers to store and showcase
                                            high-value cards, enhancing customer viewing experience.
                                        </div>
                                    </div>
                                    <div className='why-block__text-item text-item8'>
                                        <div className='why-block__text-item-content'>
                                            <span>Weather Monitoring: </span>
                                            Receive temperature and humidity alerts via mobile app.
                                        </div>
                                    </div>
                                </div>
                                <div className='why-block__list-top-image'>
                                    <ImageLink className='why-block__case-image' name='why-smart-case.webp' />
                                </div>
                            </div>
                            <div className='why-block__content-image-wrapper'>
                                <ImageLink className='why-block__case-image' name='why-smart-case.webp' />
                            </div>
                            <div className='why-block__list-bottom'>
                                <div className='why-block__list-bottom-texts'>
                                    <div className='why-block__text-item text-item1'>
                                        <div className='why-block__text-item-content'>
                                            <span>Home Collections:</span> Ideal for collectors who want to organize and
                                            display
                                            their cards securely
                                            at home, with easy access for showing to friends and family.
                                        </div>
                                    </div>
                                    <div className='why-block__text-item text-item3'>
                                        <div className='why-block__text-item-content'>
                                            <span>Mobile App Integration. </span> <br />
                                            Offers a comprehensive digital inventory, valuation tool with access to over
                                            a
                                            million
                                            graded cards that provide market insights, making collection management
                                            efficient
                                            and
                                            informed.
                                        </div>
                                    </div>
                                    <div className='why-block__text-item text-item5'>
                                        <div className='why-block__text-item-content'>
                                            <span>Travel: </span>
                                            Compact and secure for collectors traveling to buy, sell, or trade cards,
                                            ensuring their collection stays protected on the go.
                                        </div>
                                    </div>
                                    <div className='why-block__text-item text-item7'>
                                        <div className='why-block__text-item-content'>
                                            <span>Trade Shows and Conventions: </span>
                                            Perfect for safely transporting valuable cards to trade
                                            shows, conventions, and meetings with fellow collectors.
                                        </div>
                                    </div>
                                </div>
                                <div className='why-block__list-bottom-image'>
                                    <ImageLink className='why-block__case-image' name='why-smart-case.webp' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
