import "./button.scss";

export function Button({
                           children,
                           type,
                           color = "primary",
                           name,
                           className,
                           inline,
                           loading,
                           disabled,
                           onClick,
                       }) {
    const rootClassNames = `button ${className} button--${color} ${inline && "button--inline"} ${
        loading && "button--loading"
    } ${disabled && "button--disabled"}`;

    return (
        <button type={type} name={name} className={rootClassNames} disabled={disabled || loading} onClick={onClick}>
            <div className='button__inner'>{children}</div>
        </button>
    );
}
