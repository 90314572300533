import UAParser from "ua-parser-js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./home-carousel.scss";
import { SvgIcon } from "../../../components/svg-icon/svg-icon";
import { ImageLink } from "../../../components/image/image";
import { useEffect, useState } from "react";

const ButtonGroup = ({next, previous, count, ...rest}) => {
    const {carouselState: {currentSlide, totalItems}} = rest;
    return (
        <div className="carousel-button-group">
            <div className={`carousel-button carousel-button-previous ${currentSlide === 0 ? 'disabled' : ''}`}
                 onClick={() => previous()}>
                <SvgIcon name='arrow-left' className='carousel-button-icon' />
            </div>
            <div
                className={`carousel-button carousel-button-next ${currentSlide === (totalItems - count) ? 'disabled' : ''}`}
                onClick={() => currentSlide !== (totalItems - count) && next()}>
                <SvgIcon name='arrow-right' className='carousel-button-icon' />
            </div>
        </div>
    );
};

export const HomeCarousel = ({deviceType}) => {
    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024,
            },
            items: 1,
            partialVisibilityGutter: 0,
        },
        tablet: {
            breakpoint: {
                max: 1150,
                min: 600,
            },
            items: 1,
            partialVisibilityGutter: 0,
        },
        mobile: {
            breakpoint: {
                max: 600,
                min: 0,
            },
            items: 1,
            partialVisibilityGutter: 0,
        },
    };
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='what-inside'>
            <div className=''>
                <div className="carousel-cases">
                    <Carousel
                        infinite={false}
                        autoPlay={false}
                        swipeable
                        deviceType={deviceType}
                        draggable={false}
                        showDots={true}
                        itemClass="carousel-item"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        responsive={responsive}
                        arrows={false}
                        customButtonGroup={<ButtonGroup count={windowSize.width < 1200 ? 1 : 2} />}
                    >
                        <div className="carousel-cases-item slide-1">
                            <div className='container'>
                                <div className='carousel-cases-item__title'>What is inside?</div>
                                <div className='carousel-cases-item__content'>
                                    <div className='carousel-cases-item__image'>
                                        <ImageLink name='carousel/slide-1.webp' className='carousel-image' />
                                    </div>

                                    <p className='carousel-cases-item__text'>
                                        Modular case structure featuring a variable <span>block system</span> for
                                        personalized collecting.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-cases-item slide-2">
                            <div className='container'>
                                <div className='carousel-cases-item__title'>What is inside?</div>
                                <div className='carousel-cases-item__content'>
                                    <div className='carousel-cases-item__image'>
                                        <ImageLink name='carousel/slide-2.webp' className='carousel-image' />
                                    </div>

                                    <p className='carousel-cases-item__text'>
                                        Blocked system includes <span>Smart Fit</span> compartments for PSA, SGC, BGS or
                                        MagPro and most other card slabs ranging from 35 to 180 points.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-cases-item slide-3">
                            <div className='container'>
                                <div className='carousel-cases-item__title'>What is inside?</div>
                                <div className='carousel-cases-item__content'>
                                    <div className='carousel-cases-item__image'>
                                        {windowSize.width > 600 &&
                                            <ImageLink name='carousel/slide-3.webp' className='carousel-image' />}
                                        {windowSize.width <= 600 &&
                                            <ImageLink name='carousel/slide-3-mobile.webp'
                                                       className='carousel-image' />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-cases-item slide-4">
                            <div className='container'>
                                <div className='carousel-cases-item__title'>What is inside?</div>
                                <div className='carousel-cases-item__content'>
                                    <div className='carousel-cases-item__image'>
                                        <ImageLink name='carousel/slide-4.webp' className='carousel-image' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-cases-item slide-5">
                            <div className='container'>
                                <div className='carousel-cases-item__title'>What is inside?</div>
                                <div className='carousel-cases-item__wrapper'>
                                    <div className='carousel-cases-item__content content-first'>
                                        <div className='carousel-cases-item__image'>
                                            <ImageLink name='carousel/slide-5-1.webp' className='carousel-image' />
                                        </div>

                                        <p className='carousel-cases-item__text'>
                                            The <span>spring mechanism</span> is integrated into
                                            the smart card detection system, signaling
                                            the presence of the card when triggered.
                                        </p>
                                    </div>
                                    <div className='carousel-cases-item__content content-second'>
                                        <p className='carousel-cases-item__text'>
                                            <span>Hall sensor</span> ensures accurate and
                                            reliable detection of the lid's position.
                                        </p>

                                        <div className='carousel-cases-item__image'>
                                            <ImageLink name='carousel/slide-5-2.webp' className='carousel-image' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {windowSize.width < 1200 && <div className="carousel-cases-item slide-5-2">
                            <div className='container'>
                                <div className='carousel-cases-item__title'>What is inside?</div>
                                <div className='carousel-cases-item__content content-second'>
                                    <p className='carousel-cases-item__text'>
                                        <span>Hall sensor</span> ensures accurate and
                                        reliable detection of the lid's position.
                                    </p>

                                    <div className='carousel-cases-item__image'>
                                        <ImageLink name='carousel/slide-5-2-mobile.webp' className='carousel-image' />
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div className="carousel-cases-item slide-6">
                            <div className='container'>
                                <div className='carousel-cases-item__title'>What is inside?</div>
                                <div className='carousel-cases-item__content'>
                                    <div className='carousel-cases-item__image'>
                                        {windowSize.width > 1200 &&
                                            <ImageLink name='carousel/slide-6.webp' className='carousel-image' />}
                                        {windowSize.width <= 1200 &&
                                            <ImageLink name='carousel/slide-6-mobile.webp'
                                                       className='carousel-image' />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

HomeCarousel.getInitialProps = ({req}) => {
    let userAgent;
    if (req) {
        userAgent = req.headers["user-agent"];
    } else {
        userAgent = navigator.userAgent;
    }
    const parser = new UAParser();
    parser.setUA(userAgent);
    const result = parser.getResult();
    const deviceType = (result.device && result.device.type) || "desktop";
    return {deviceType};
};
