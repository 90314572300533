import { ImageLink } from "../image/image";
import "./header.scss";
import { Button } from "../button/button";
import { useState } from "react";
import { SvgIcon } from "../svg-icon/svg-icon";

export const Header = () => {
    const [active, setActive] = useState(false);

    const onActivateMenu = () => {
        setActive(!active);
    };

    const onCloseMenu = () => {
        setActive(false);
    };

    return (
        <div className='header' id='home'>
            <div className='header__container'>
                <div className='header__container-logo'>
                    <ImageLink name='logo.svg' className='logo' />
                    <span>SmartCase</span>
                </div>
                <div className='header__container-content'>
                    <div className='header__container-menu'>
                        <ul className='header__menu'>
                            <li className='header__menu-item'>
                                <a href="#home">Home</a>
                            </li>
                            <li className='header__menu-item'>
                                <a href="#whyHobbySmartCase">About</a>
                            </li>
                            <li className='header__menu-item'>
                                <a href="#whyHobbySmartCase">Features</a>
                            </li>
                            <li className='header__menu-item'>
                                <a href="https://presale.hobbysmartcase.com/" target="_blank"
                                   rel="noreferrer">Pricing</a>
                            </li>
                        </ul>
                    </div>
                    <div className='header__container-search'>
                        <div className='header__search'>
                            <SvgIcon name='search' className='icon-search' />
                        </div>
                    </div>
                    <div className='header__container-button'>
                        <a href="mailto:brian@hobbysmartcases.com" target="_blank" rel="noreferrer">
                            <Button>Get in touch</Button>
                        </a>
                    </div>

                    <div className='header__mobile-menu' onClick={onActivateMenu}>
                        <SvgIcon name='menu' className='header__icon-svg icon-menu' />
                    </div>
                    <div
                        className={`header__mobile-navigation ${
                            active ? "header__mobile-navigation--open" : "header__mobile-navigation--close"
                        }`}>
                        <div className='header__mobile-navigation-wrapper'>
                            <div className='header__mobile-action'>
                                <div className='header__mobile-action__text'>Menu</div>
                                <SvgIcon name='close' className='header__icon-svg icon-close' onClick={onActivateMenu} />
                            </div>
                            <div className='header__mobile-block'>
                                <div className='header__mobile-links'>
                                    <ul className='header__menu header__menu--mobile'>
                                        <li className='header__menu-item' onClick={onCloseMenu}>
                                            <a href="/">Home</a>
                                        </li>
                                        <li className='header__menu-item' onClick={onCloseMenu}>
                                            <a href="#whyHobbySmartCase">About</a>
                                        </li>
                                        <li className='header__menu-item' onClick={onCloseMenu}>
                                            <a href="#whyHobbySmartCase">Features</a>
                                        </li>
                                        <li className='header__menu-item' onClick={onCloseMenu}>
                                            <a href="https://presale.hobbysmartcase.com/" target="_blank"
                                               rel="noreferrer">Pricing</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className='header__mobile-divider'></div>
                                <div className='header__mobile-button'>
                                    <a href="mailto:brian@hobbysmartcases.com" target="_blank" rel="noreferrer">
                                        <Button>Get in touch</Button>
                                    </a>
                                </div>
                                <div className='header__mobile-socials'>
                                    <a href="https://www.facebook.com/profile.php?id=61557502662889" target="_blank"
                                       rel="noreferrer">
                                        <SvgIcon name='Facebook' className='footer__icon-svg' />
                                    </a>
                                    <a href="https://www.instagram.com/hobbysmartcase/" target="_blank"
                                       rel="noreferrer">
                                        <SvgIcon name='Instagram' className='footer__icon-svg' />
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
