import "./mobile-app.scss";
import { ImageLink } from "../../../components/image/image";
import InfiniteVideo from "../../../components/infinite-video/infinite-video";
import { useState } from "react";
import CollapsibleSection from "../../../components/collapsible-section/collapsible-section";

export const MobileApp = () => {
    const [openSection, setOpenSection] = useState(null);

    const handleToggle = (section) => {
        setOpenSection((prevOpenSection) => (prevOpenSection === section ? null : section));
    };

    return (
        <div className='mobile-app'>
            <div className='container'>
                <div className='mobile-app-wrapper'>
                    <div className='mobile-app__content'>
                        <div className='mobile-app__content-title'>
                            Mobile <br/> App
                        </div>
                        <CollapsibleSection
                            label="Scan & Match"
                            text="Use your phone to scan each graded card slab. Our app matches it against a comprehensive database of over a million graded card slabs covering all major grading companies."
                            isOpen={openSection === 'section1'}
                            onClick={() => handleToggle('section1')}
                        />
                        <CollapsibleSection
                            label="Confirm Match"
                            text="Confirm the scanned graded card slab and update your slab’s details from grading info to market price. If your graded card slab is not found you can add manually via database search"
                            isOpen={openSection === 'section2'}
                            onClick={() => handleToggle('section2')}
                        />
                        <CollapsibleSection
                            label="Assign to Slot and Case"
                            text="Select a free slot from the 60 available in your case, and securely place your graded card slab. Add multiple Hobby Smart Cases to your Mobile App for larger collections."
                            isOpen={openSection === 'section3'}
                            onClick={() => handleToggle('section3')}
                        />

                        <div className="mobile-app__images-wrapper">
                            <ImageLink name='google-play.png' className='image-app' />
                            <ImageLink name='app-store.png' className='image-app' />
                        </div>
                    </div>


                    <div className='mobile-app__video-container'>
                        <div className='mobile-app__video-container-phone-bg'>
                            <div className='iphone-bg'>
                                <ImageLink name='iphone.png' className='iphone' />
                                <InfiniteVideo src='/assets/videos/mobile-app-speed.mp4' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
