import "./loader.scss";

export const Loader = () => {
    return (
        <div className="containAll">
            <div className="containLoader">
                <div className="circleGroup circle-1"></div>
                <div className="circleGroup circle-2"></div>
                <div className="circleGroup circle-3"></div>
                <div className="circleGroup circle-4"></div>
                <div className="circleGroup circle-5"></div>
                <div className="circleGroup circle-6"></div>
                <div className="circleGroup circle-7"></div>
                <div className="circleGroup circle-8"></div>
            </div>
        </div>
    )
}
