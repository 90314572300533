import { SvgIcon } from "../svg-icon/svg-icon";
import "./modules-stats.scss";

export const ModulesStats = ({green}) => {
    const modules = [
        {
            name: 'Chipset',
            icon: 'cpu',
        },
        {
            name: 'Bluetooth module',
            icon: 'bluetooth',
        },
        {
            name: 'Wi-Fi',
            icon: 'wifi',
        },
        {
            name: 'Battery charger',
            icon: 'usb-drive',
        },
        {
            name: 'Battery gauge',
            icon: 'battery-full',
        },
    ]
    return (
        <div className='modules-stats-wrapper'>
            {modules.map((module, i) => (
                <div className='module' key={i}>
                    <SvgIcon name={module.icon} className={`module_icon ${green && 'green'}`} />
                    <div className='module_name'>{module.name}</div>
                </div>
            ))}
        </div>
    )
}
