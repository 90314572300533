import { useCollapse } from "react-collapsed";
import "./collapsible-section.scss";
import { SvgIcon } from "../svg-icon/svg-icon";

const CollapsibleSection = ({label, text, isOpen, onClick}) => {
    const {getCollapseProps, getToggleProps, isExpanded} = useCollapse({isExpanded: isOpen});

    return (
        <div className="collapsible-section">
            <div className="collapsible-section__title" {...getToggleProps({onClick})}>
                {label}
                <SvgIcon name='arrow-right' className={`collapsible-section__icon ${isExpanded ? 'rotate' : ''}`} />
            </div>
            <section {...getCollapseProps()}>
                <div className='collapsible-section__text'>
                    {text}
                </div>
            </section>
        </div>
    );
};

export default CollapsibleSection;
