import { SvgIcon } from "../svg-icon/svg-icon";
import "./footer.scss";
import { ImageLink } from "../image/image";
import { Button } from "../button/button";

export const Footer = () => {
    return (
        <footer className='footer'>
            <div className='container'>
                <div className='footer__content'>
                    <div className='footer__content-top'>
                        <div className='footer__content-logo'>
                            <ImageLink name='logo.svg' className='logo' />
                        </div>
                        <div className='footer__content-menu'>
                            <div className='footer__content-menu-items'>
                                <div className='footer__content-menu-item'>
                                    <a href="#home">Home</a>
                                </div>
                                <div className='footer__content-menu-item'>
                                    <a href="#whyHobbySmartCase">About</a>
                                </div>
                            </div>
                            <div className='footer__content-menu-items'>
                                <div className='footer__content-menu-item'>
                                    <a href="#whyHobbySmartCase">Features</a>
                                </div>
                                <div className='footer__content-menu-item'>
                                    <a href="https://presale.hobbysmartcase.com/" target="_blank"
                                       rel="noreferrer">Pricing</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='footer__content-bottom'>
                        <hr className='footer__divider divider-mobile' />
                        <div className='footer__content-subscribe'>
                            <a href="mailto:brian@hobbysmartcases.com" target="_blank" rel="noreferrer">
                                <Button className='footer__content-subscribe-button'>Get in touch</Button>
                            </a>
                        </div>
                    </div>

                </div>
                <hr className='footer__divider' />
                <div className='footer__info'>
                <div className='footer__info-socials'>
                        <a href="https://www.facebook.com/profile.php?id=61557502662889" target="_blank"
                           rel="noreferrer">
                            <SvgIcon name='Facebook' className='footer__icon-svg' />
                        </a>
                        <a href="https://www.instagram.com/hobbysmartcase/" target="_blank"
                           rel="noreferrer">
                            <SvgIcon name='Instagram' className='footer__icon-svg' />
                        </a>
                    </div>
                    <div className='footer__info-content'>
                        <div>
                            <a href="mailto:brian@hobbysmartcases.com" className='footer__info-content-mail'>
                                brian@hobbysmartcases.com
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
