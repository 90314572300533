import "./svg-sprite.scss";
import { ReactComponent as Sprite } from "./sprite.svg";

export function SvgSprite() {
    return (
        <div className='svg-sprite'>
            <Sprite />
        </div>
    );
}

export default SvgSprite;
