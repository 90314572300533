export const ImageLink = ({ name, className, onLoad }) => {
    return (
        <img
            className={`${className}`}
            src={`${process.env.PUBLIC_URL}/assets/images/${name}`}
            alt={name}
            title={name}
            onLoad={onLoad}
        />
    );
};
