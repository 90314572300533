import { useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";


const InfiniteVideo = ({ src }) => {
    const videoRef = useRef(null);
    const { ref, inView } = useInView({
        threshold: 1,
        triggerOnce: false,
    });

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.muted = true;
            const isPlaying = videoElement.currentTime > 0 && !videoElement.paused && !videoElement.ended
                && videoElement.readyState > videoElement.HAVE_CURRENT_DATA;

            if (inView && !isPlaying) {
                videoElement.play();
            } else {
                videoElement.pause();
            }
        }
    }, [inView]);

    return (
        <div ref={ref}>
            <video ref={videoRef} autoPlay loop muted controls={false} playsInLine="playsinline">
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export default InfiniteVideo;
